import {omit} from 'lodash'
import React from 'react'
import {KTSVG, TableSkeleton} from '..'
import A1_CONSTANTS from '../../../app/modules/a1/core/_const'
import {TableBody, TableWrapper} from '../../widgets'

const SkeletonA1BatchDetail: React.FC = () => (
  <>
    <div className='d-flex flex-wrap mb-10'>
      <div className='col-12 col-xxl-6'>
        <div className='card me-xxl-4 h-100'>
          <div className='card-header border-0 fw-bolder d-flex justify-content-between align-items-center'>
            <span className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
          <div className='card-body bg-light rounded-bottom d-flex flex-column flex-md-row'>
            <div className='col-12 col-md-6'>
              <div className='d-flex pe-md-6 text-primary fs-5 fs-xxl-7 text-center fw-bolder'>
                <div className='col-4'>
                  <span className='fs-1 col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
                <div className='col-4'>
                  <span className='fs-1 col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
                <div className='col-4'>
                  <span className='fs-1 col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
              </div>
              <div className='d-flex flex-column min-h-150px pe-md-6'></div>
            </div>
            <span className='border border-secondary my-8 my-md-n8' />
            <div className='col-12 col-md-6 ps-6 fs-5 fs-xxl-7 fw-bold'>
              <div className='col-12 d-flex border-bottom border-secondary min-h-50px' />
              <div className='col-12 d-flex border-bottom border-secondary min-h-50px' />
              <div className='col-12 d-flex border-bottom border-secondary min-h-50px' />
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-xxl-6'>
        <div className='card mt-4 mt-xxl-0 ms-xxl-4 h-100'>
          <div className='card-header border-0 d-flex justify-content-between align-items-center fw-bolder'>
            <span className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
          <div className='card-body d-flex flex-column scroll-x pb-6'>
            <div className='d-flex text-center mb-6'>
              {Object.entries(omit(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS, 'N_A')).map(
                ([key, status]) => (
                  <div className='col-7-equal min-w-100px position-relative' key={key}>
                    <div className='w-100 border border-2 border-secondary position-absolute top-50 start-50 translate-middle' />
                    <KTSVG
                      path={`/media/gori/a1/checkbox_unchecked.svg`}
                      className='svg-icon-1 position-absolute top-50 start-50 translate-middle'
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <TableWrapper>
      <TableBody>
        <div className='d-flex justify-content-end my-5'>
          <span className='btn col-2 placeholder placeholder-lg rounded-2 bg-secondary' />
        </div>
        <TableSkeleton countRow={8} />
      </TableBody>
    </TableWrapper>
  </>
)

export {SkeletonA1BatchDetail}
