import clsx from 'clsx'
import {useFormik} from 'formik'
import {isUndefined} from 'lodash'
import {useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import {useAuth} from '..'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {Button, InputTextFormik} from '../../../../_gori/widgets'
import {InputPassword} from '../../../../_gori/widgets/form/InputPassword'
import CommonService from '../../common/core/_requests'
import AuthService from '../core/_requests'

export function Login() {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, infoYup} = UseYupValidate()
  const {saveAuth, handleSaveCurrentUser} = useAuth()
  const navigate = useNavigate()

  const passwordRef = useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState<{
    login: boolean
    resentEmail: boolean
  }>({
    login: false,
    resentEmail: false,
  })
  const [statusForm, setStatusForm] = useState<{status: 'error'; message: string} | undefined>(
    undefined
  )

  const loginSchema = Yup.object().shape({
    email: infoYup.email(),
    password: stringYup(50, 'PASSWORD'),
  })

  const initialValues = {
    email: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setStatusForm(undefined)
      try {
        setLoading((prev) => ({...prev, login: true}))
        const config = {cancelToken: newCancelToken()}
        const login: any = await AuthService.login(values, config)
        await saveAuth(login)
        const {data} = await CommonService.getUser(config)
        await handleSaveCurrentUser(data)
        await navigate('/a1-batches')
      } catch (error: any) {
        if (isCancel(error)) return
        saveAuth(undefined)
        let errorMessage = error?.response?.data?.message
        if (errorMessage) {
          setStatusForm({
            status: 'error',
            message: intl.formatMessage({id: errorMessage}),
          })
        }
      } finally {
        setLoading((prev) => ({...prev, login: false}))
      }
    },
  })

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'SIGN_IN'})}</h1>
            </div>
            {!isUndefined(statusForm) && (
              <div
                className={clsx('mb-lg-10 alert', {
                  'alert-danger': statusForm.status === 'error',
                })}
              >
                <div className='alert-text font-weight-bold'>{statusForm.message}</div>
              </div>
            )}
            <InputTextFormik
              className='mb-10'
              labelClassName='fw-bolder text-dark fs-6'
              label={intl.formatMessage({id: 'EMAIL'})}
              required
              formik={formik}
              name='email'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (
                    formik.values.email?.trim() &&
                    !formik.values.password?.trim() &&
                    passwordRef.current
                  ) {
                    passwordRef.current.focus()
                  } else if (formik.values.email?.trim() && formik.values.password?.trim()) {
                    formik.handleSubmit()
                  }
                }
              }}
            />
            <div className='fv-row mb-10 fv-plugins-icon-container'>
              <InputPassword
                labelClassName={'fw-bolder text-dark fs-6 mb-0'}
                label={intl.formatMessage({id: 'PASSWORD'})}
                ref={passwordRef}
                tabIndex={2}
                size='lg'
                openHighlight={false}
                formik={formik}
                name={'password'}
                required
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    formik.handleSubmit()
                  }
                }}
              />
            </div>
            <div className='text-center'>
              <Button
                className='btn btn-lg btn-primary w-100 mb-5'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'LOGGING_IN'})}
                loading={loading.login}
                disabled={loading.login}
                event={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
