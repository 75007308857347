import {useEffect, useRef} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {MenuComponent, SwapperComponent} from '../assets/ts/components'
import {AsideProvider, MeterProvider} from '../providers'
import {AsideLayout} from './components/aside'
import {Content} from './components/Content'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {PageDataProvider} from './core'

const MasterLayoutForNoAuth = () => {
  const location = useLocation()
  const timer: any = useRef()

  useEffect(() => {
    timer.current = setTimeout(() => {
      SwapperComponent.reinitialization()
      MenuComponent.reinitialization()
    }, 100)
    return () => {
      clearTimeout(timer.current)
    }
  }, [location.key])

  return (
    <PageDataProvider>
      <MeterProvider>
        <div className='d-flex flex-column flex-root'>
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideProvider>
              <AsideLayout />
            </AsideProvider>
            <div className='wrapper d-flex flex-column flex-row-fluid me-xxl-5' id='gori_wrapper'>
              <HeaderWrapper />
              <div id='gori_content' className='content d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ScrollTop />
      </MeterProvider>
    </PageDataProvider>
  )
}

export {MasterLayoutForNoAuth}
