import React, {useContext, useEffect, useState} from 'react'
import {StorageHelpers} from '../helpers/StorageHelpers'

const STATES_GLOBAL_STORAGE_KEY =
  process.env.REACT_APP_STATES_GLOBAL_STORAGE_KEY || 'STATES_GLOBAL_STORAGE_KEY'

export interface StatesGlobalContextModel {
  statesGlobal: any
}

const initStatesGlobalContextPropsStates = {
  statesGlobal: StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY),
}

const StatesGlobalContext = React.createContext<StatesGlobalContextModel>(
  initStatesGlobalContextPropsStates
)

const StatesGlobalProvider: React.FC = ({children}) => {
  let [statesGlobal, setStateGlobal] = useState(
    StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY)
  )

  // Update global state when making the first API call.
  useEffect(() => {
    setStateGlobal(StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY))
  }, [])

  return (
    <StatesGlobalContext.Provider
      value={{
        statesGlobal,
      }}
    >
      {children}
    </StatesGlobalContext.Provider>
  )
}

function useStatesGlobal() {
  return useContext(StatesGlobalContext)
}

export {StatesGlobalProvider, useStatesGlobal}
