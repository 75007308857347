const UPLOAD_CONFIG = {
  MAX_UPLOAD: 5000,
  SIZE: 8388608,
  SUPPORTED_FORMATS: ['text/csv'],
}

const FORMAT_DATE = {
  DATE_TIME: 'YYYY-MM-DD hh:mm A',
  DATE: 'YYYY-MM-DD',
}

const STATUS_ADD_TO_A1_BATCH = {
  ACTIVE_BOX: {
    SMART: 'smart',
    CREATE: 'create',
    EXISTING: 'existing',
  },
  ACTION: {
    CREATE: 'create',
    EXCLUDE: 'exclude',
    MOVE: 'move',
  },
}

const A1_BATCH_STATUS = {
  OPEN: 'open',
  PROCESSED: 'processed',
  ARCHIVED: 'archived',
}

const OPTIONS_JOURNEY_INFO = {
  INCOMPLETE: {label: 'INCOMPLETE', value: 'incomplete'},
  COMPLETE: {label: 'COMPLETE', value: 'complete'},
}

const OPTIONS_JOURNEY_STATUS = {
  N_A: {label: 'N_A', value: [null], color: '#FF7272'},
  READY: {label: 'READY', value: ['ready'], color: '#A9DEC8'},
  PICK_UP_DROP_OFF: {
    label: 'PICK_UP_DROP_OFF',
    value: ['pickup_dropoff', 'picked_up'], // Value 1 is Status Primary, Value 2 is Status Hidden
    color: '#085E57',
  },
  AIR_DELIVERY_ARRANGED: {
    label: 'AIR_DELIVERY_ARRANGED',
    value: ['air_delivery', 'air_delivery_arranged'], // Value 1 is Status Primary, Value 2 is Status Hidden
    color: '#14B8A6',
  },
  CUSTOMS: {label: 'CUSTOMS', value: ['customs', 'dropped_off'], color: '#D7B3FC'}, // Value 1 is Status Primary, Value 2 is Status Hidden
  TRANSFERRED_TO_LOCAL_CARRIER: {
    label: 'TRANSFERRED_TO_LOCAL_CARRIER',
    value: ['transferred'],
    color: '#D7B3FC',
  },
  DELIVERING: {label: 'DELIVERING', value: ['delivering'], color: '#550F8C'},
  DELIVERED: {label: 'DELIVERED', value: ['delivered'], color: '#550F8C'},
}

const OPTIONS_ORIGIN = [
  {label: 'SZX', value: 'SZX'},
  {label: 'PEK', value: 'PEK'},
  {label: 'SHA', value: 'SHA'},
  {label: 'HAN (Vietnam)', value: 'HAN'},
]

const OPTIONS_DESTINATION = [
  {label: 'LAX', value: 'LAX'},
  {label: 'ORD', value: 'ORD'},
  {label: 'JFK', value: 'JFK'},
  {label: 'ATL', value: 'ATL'},
]

const OPTIONS_A1_SERVICE = {
  ALL_IN_ONE: {
    label: 'A1_ALL_IN_ONE',
    value: 'all_in_one',
  },
  CUSTOMIZE: {
    label: 'CUSTOMIZE',
    value: 'customize',
    isDisabled: true,
  },
}

export const OPTIONS_CONTENT_TYPE = [
  {label: 'MERCHANDISE', value: 'merchandise'},
  {label: 'GIFT', value: 'gift'},
  {label: 'DOCUMENTS', value: 'documents'},
  {label: 'SAMPLE', value: 'sample'},
  {label: 'RETURN', value: 'return'},
  {label: 'HUMANITARIAN', value: 'humanitarian'},
  {label: 'DANGEROUS_GOODS', value: 'dangerousgoods'},
  {label: 'OTHER', value: 'other'},
]

const PARAMS_A1_BATCHES_FILTER = [
  'date_from',
  'date_to',
  'name',
  'code',
  'journey_info',
  'journey_status',
  'origin_airport',
  'destination_airport',
]

const UNIT_VALUES = {
  WEIGHT_CARRIER: {
    oz: {max: 1120},
    lb: {max: 70},
    gm: {max: 31751},
    kg: {max: 31.5},
  },
  WEIGHT: {
    oz: {max: 10000},
    lb: {max: 625},
    gm: {max: 283495},
    kg: {max: 283.5},
  },
  DIMENSION: {
    in: {max: 100},
    cm: {max: 254},
    mm: {max: 2540},
  },
}

const PERMISSION = {
  ORIGIN: 'origin',
  DESTINATION: 'destination',
}

const MAX_LAYOVER = 5

export const SORT_KEYS_ORIGIN_VALIDATE = [
  'origin',
  'layover',
  'destination',
  'mawb',
  'shipment_detail',
  'charges',
]

const A1_RELEASE_STATUS = {
  RELEASED: {label: 'RELEASED', value: 'released'},
  HOLD: {label: 'HOLD', value: 'hold'},
}

const MASS_UPDATE = {
  DESTINATION: [{label: 'RELEASE_STATUS', value: 'release_status'}],
}

const A1_CONSTANTS = {
  UPLOAD_CONFIG,
  FORMAT_DATE,
  STATUS_ADD_TO_A1_BATCH,
  A1_BATCH_STATUS,
  OPTIONS_JOURNEY_INFO,
  OPTIONS_JOURNEY_STATUS,
  OPTIONS_ORIGIN,
  OPTIONS_DESTINATION,
  OPTIONS_A1_SERVICE,
  OPTIONS_CONTENT_TYPE,
  PARAMS_A1_BATCHES_FILTER,
  UNIT_VALUES,
  PERMISSION,
  MAX_LAYOVER,
  SORT_KEYS_ORIGIN_VALIDATE,
  A1_RELEASE_STATUS,
  MASS_UPDATE,
}

export default A1_CONSTANTS
