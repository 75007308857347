/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy, Suspense} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_gori/assets/ts/_utils'
import {DisableSidebar} from '../../_gori/layout/core'
import {MasterLayoutForNoAuth} from '../../_gori/layout/MasterLayoutForNoAuth'
import {App} from '../App'
import {AuthPage, Logout, useAuth} from '../modules/auth'
import {PrivacyPolicyPage} from '../modules/common'
import {ErrorsPage, MaintenancePage} from '../modules/errors'
import {PrivateRoutes} from './PrivateRoutes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const SupportWrapper = lazy(() => import('../pages/support/SupportWrapper'))
  const {currentUser} = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <>
            <Route path='maintenance' element={<MaintenancePage />} />
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            <Route path='privacy-policy/*' element={<PrivacyPolicyPage />} />
            {currentUser ? (
              <Route path='/*' element={<PrivateRoutes />} />
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route element={<MasterLayoutForNoAuth />}>
                  <Route
                    path='support/*'
                    element={
                      <SuspenseView>
                        <SupportWrapper />
                      </SuspenseView>
                    }
                  />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </Route>
              </>
            )}
          </>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const SuspenseView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {AppRoutes, SuspenseView}
