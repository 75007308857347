import {AxiosRequestConfig} from 'axios'
import axiosConfig from '../../../api/axiosConfig'

const BASE_URL = 'profile'

function getUser(config?: AxiosRequestConfig | undefined) {
  return axiosConfig.get(`/${BASE_URL}`, config)
}

// const updateLanguage = (userId, payload, config?: AxiosRequestConfig | undefined) => {
//   return axiosConfig.put(`/users/profile/general/${userId}`, payload, config)
// }

const CommonService = {
  getUser,
  // updateLanguage,
}

export default CommonService
