import {useCallback} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {REGEX_DECIMAL, REGEX_EMAIL, REGEX_PASSWORD} from '../constants'
import {yupHelper} from '../helpers'

const UseYupValidate = () => {
  const intl = useIntl()
  const yup = yupHelper(intl)

  const stringYup = (valueLabel, name, required = true) => {
    switch (valueLabel) {
      case 'no_limit':
        return yup.string({
          name: name,
          required: required,
          trim: true,
        })

      default:
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {value: valueLabel, label: valueLabel},
        })
    }
  }

  const numberYup = {
    journey_info: useCallback(
      (name = 'JOURNEY', required = true) => {
        return yup.number({
          name: name,
          required: required,
          trim: true,
          integer: true,
          min: {
            value: 0.1,
            label: 0,
          },
          max: {
            value: 99999.99,
            label: '99,999.99',
          },
        })
      },
      [yup]
    ),
    decimal: useCallback(
      (name, required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          regex: {
            value: REGEX_DECIMAL,
            label: 'DECIMAL_IS_INVALID',
          },
        })
      },
      [yup]
    ),
  }

  const dateYup = useCallback(
    (name, minDate = '2000-01-01T00:00:00Z', required = true, maxDate = '2099-12-31T23:59:59Z') => {
      let yup = Yup.date()
        .nullable()
        .typeError(intl.formatMessage({id: 'INVALID_DATE'}))
      if (minDate) {
        yup = yup.min(minDate, intl.formatMessage({id: 'INVALID_DATE'}))
      }
      if (maxDate) {
        yup = yup.max(maxDate, intl.formatMessage({id: 'INVALID_DATE'}))
      }
      if (required) {
        yup = yup.required(
          intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
        )
      }
      return yup
    },
    [intl]
  )

  const infoYup = {
    email: useCallback(
      (name = 'EMAIL', required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 100,
            label: 100,
          },
          regex: {
            value: REGEX_EMAIL,
            label: 'PLEASE_ENTER_VALID_EMAIL_ADDRESS',
          },
        })
      },
      [yup]
    ),
  }

  // BEGIN: auth
  const authYup = {
    password: useCallback(
      (required = true) => {
        return yup.string({
          name: 'PASSWORD',
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          regex: {
            value: REGEX_PASSWORD,
            label: 'PLEASE_ENTER_VALID_PASSWORD',
          },
        })
      },
      [yup]
    ),
    passwordConfirmation: useCallback(
      (required = true) => {
        return yup.string({
          name: 'CONFIRM_PASSWORD',
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          when: {
            target: 'password',
            is: (val: string) => !!(val && val.length > 0),
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              intl.formatMessage({id: 'PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH'})
            ),
          },
        })
      },
      [intl, yup]
    ),
  }
  // END: auth

  return {
    stringYup,
    numberYup,
    dateYup,
    infoYup,
    authYup,
  }
}

export default UseYupValidate
