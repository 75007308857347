import React from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {fileHelper, formatBytes} from '../../helpers'
import {Button} from '../form/Button'
import clsx from 'clsx'
import {KTSVG} from '../../components'

type Props = {
  className?: any
  supportedFormat: any
  maxRow: number
  size: {
    label: string
    value: number
  }
  onSubmit: any
}

const UploadFile: React.FC<Props> = ({className, supportedFormat, maxRow, size, onSubmit}) => {
  const intl = useIntl()
  const [fileInput, setFileInput] = React.useState<any>(null)

  const setFile = async (e: any) => {
    const file = e.target.files[0]
    if (!file) return

    if (!supportedFormat.includes(file.type)) {
      return toast.error(intl.formatMessage({id: 'INVALID_FILE_TYPE_CSV_ONLY'}))
    }

    const {row} = await fileHelper.checkRowHaveData(file)
    if (row > maxRow) {
      return toast.error(
        intl.formatMessage({id: 'CANNOT_UPLOAD_MORE_THAN_INPUT_ITEMS'}, {input: maxRow})
      )
    }

    if (file.size > size.value) {
      return toast.warning(
        intl.formatMessage(
          {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_UPLOAD_FILES_SMALLER_THAN_INPUT'},
          {fileName: file.name, size: size.label}
        )
      )
    }

    setFileInput(file)
    onSubmit({file: file})
    e.target.value = null
  }

  const handleDelete = (key: any) => {
    setFileInput(null)
    onSubmit({file: null})
  }

  return (
    <>
      <div className={clsx('dropzone', className)}>
        <div className='d-flex justify-content-center my-4'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='fs-4 fw-bold text-dark mb-1'>
              {intl.formatMessage({id: 'DRAG_AND_DROP_HERE_OR'})}
              <span className='ms-2 text-primary'>{intl.formatMessage({id: 'BROWSE_FILES'})}</span>
            </div>
            <Button
              className='btn btn-secondary mt-4'
              label={intl.formatMessage({id: 'UPLOAD_FILE'})}
            />
          </div>
          <input
            type='file'
            id='file-upload'
            name='file-img'
            onChange={(e: any) => setFile(e)}
            className='dropzone__upload mt-n10 cursor-pointer'
            accept={supportedFormat.join(',')}
          />
        </div>
      </div>
      {fileInput && (
        <div className='d-flex align-items-center mt-2 col-6'>
          <div className='flex-fill d-flex justify-content-between bg-light-primary p-3 rounded-2 fw-bold w-100'>
            <span className='text-truncate'>{fileInput?.name}</span>
            <span className='text-nowrap'>{formatBytes(fileInput?.size)}</span>
          </div>

          <div
            className='text-hover-danger cursor-pointer ms-4'
            onClick={() => handleDelete(fileInput?.key)}
          >
            <KTSVG path='/media/gori/common/empty.svg' small={false} />
          </div>
        </div>
      )}
    </>
  )
}

export {UploadFile}
