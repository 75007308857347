import React, {useContext, useState} from 'react'
import {A1BatchesProviderModel} from '../../app/modules/a1/core/_models'

const initA1BatchContextPropsState = {
  a1Batch: null,
  setA1Batch: () => {},
}

const A1BatchContext = React.createContext<A1BatchesProviderModel>(initA1BatchContextPropsState)

const A1BatchesProvider: React.FC = ({children}) => {
  const [a1Batch, setA1Batch] = useState<any>(initA1BatchContextPropsState.a1Batch)

  return <A1BatchContext.Provider value={{a1Batch, setA1Batch}}>{children}</A1BatchContext.Provider>
}

function useA1BatchesProvider() {
  return useContext(A1BatchContext)
}

export {A1BatchesProvider, useA1BatchesProvider}
