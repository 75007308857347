export const KEY_PERMISSIONS = {
  SYSTEM_ADMIN: 'system admin',
  ADMIN: 'admin',
  A1_BATCHES: 'a1-batches',
  A1_BATCHES_VIEW: 'a1-batches:view',
  A1_BATCHES_CREATE: 'a1-batches:create',
  A1_BATCHES_UPDATE: 'a1-batches:update',
  A1_BATCHES_DELETE: 'a1-batches:delete',
}

// route: First route that is goto if you have permission for it
// subRoutes: For highlighting the left menu
export const ROUTING_PERMISSIONS = {
  A1_BATCHES: {
    hasPermission: true,
    route: '/a1-batches',
    roles: [],
  },
  A1_BATCHES_VIEW: {
    hasPermission: false,
    route: '/a1-batches',
    roles: [KEY_PERMISSIONS.A1_BATCHES_VIEW],
  },
  A1_BATCHES_CREATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.A1_BATCHES_CREATE],
  },
  A1_BATCHES_UPDATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.A1_BATCHES_UPDATE],
  },
  A1_BATCHES_DELETE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.A1_BATCHES_DELETE],
  },
  SUPPORTS: {
    hasPermission: true,
    route: '/support',
  },
}
