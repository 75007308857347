import {read, utils} from 'xlsx'

const allowTestOnly = (currentUser) => {
  return currentUser?.username === 'test' || currentUser?.username === 'admin'
}

const setLoadingActionTable = (
  tableData: Array<object>,
  listId: Array<number>,
  status: boolean,
  key = 'isLoading'
) => {
  const setIsLoading = tableData.filter((row: any) => {
    listId.forEach((id: number) => {
      if (row.id === id) {
        row[key] = status
      }
    })
    return listId.includes(row.id)
  })

  return tableData.map((row: any) => setIsLoading.find((item: any) => item.id === row.id) || row)
}

const checkRowHaveData = async (file) => {
  let row = 1
  let header: any[] = []

  try {
    const data = await file.arrayBuffer()
    const wb = read(data)
    const ws = wb.Sheets[wb.SheetNames[0]]
    const range = utils.decode_range(ws['!ref'] || '')
    row = range.e.r - range.s.r + 1
    const dataExcel = utils.sheet_to_json(ws, {header: 1, blankrows: false})
    const nonEmptyRows = dataExcel.filter((row: any) => row.length > 0)

    // Calculate the row count, ignoring the first row (header)
    row = nonEmptyRows.length > 0 ? nonEmptyRows.length - 1 : 0

    // Set the header to the first non-empty row
    header = (nonEmptyRows.length > 0 ? nonEmptyRows[0] : []) as any[]
  } catch (error) {
    // pass
  }

  return {row, header}
}

const fileHelper = {
  checkRowHaveData,
}

export {allowTestOnly, fileHelper, setLoadingActionTable}
