import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import {useAuth} from '../../app/modules/auth'

type LanguageProviderModel = {
  language: any
  setLanguage: any
  handleUpdateLanguage: any
}

const initLanguageContextPropsState = {
  language: 'en',
  setLanguage: () => {},
  handleUpdateLanguage: () => {},
}

const I18nContext = React.createContext<LanguageProviderModel>(initLanguageContextPropsState)
const MetronicI18nProvider: FC = ({children}) => {
  const {currentUser} = useAuth()
  const [language, setLanguage] = useState(
    currentUser?.language || initLanguageContextPropsState.language
  )

  useEffect(() => {
    setLanguage(currentUser?.language || initLanguageContextPropsState.language)
  }, [currentUser?.language])

  const handleUpdateLanguage = useCallback(async (userId, language) => {
    // await CommonService.updateLanguage(userId, {language: language})
    window.location.reload()
  }, [])

  return (
    <I18nContext.Provider value={{language, setLanguage, handleUpdateLanguage}}>
      {children}
    </I18nContext.Provider>
  )
}

const useLanguage = () => {
  return useContext(I18nContext)
}

export {MetronicI18nProvider, useLanguage}
