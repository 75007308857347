import {isEmpty} from 'lodash'
import React from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Props = {
  handleClose?: () => void
  response?: any
}

const ValidationErrorModal: React.FC<Props> = ({handleClose, response}) => {
  const intl = useIntl()
  const dataErrors = response?.data

  return (
    <Modal
      id='gori_order_modal_error'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={!isEmpty(response)}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'ERRORS'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y bg-light-danger text-danger'>
          <Container>
            {dataErrors?.message && (
              <div className='fw-bolder fs-3 mb-5 text-center'>
                {!isEmpty(dataErrors?.errors)
                  ? intl.formatMessage({id: 'VALIDATION_ERRORS'})
                  : dataErrors?.message}
              </div>
            )}
            {!isEmpty(dataErrors?.errors) &&
              Object.entries(dataErrors?.errors).map(([key, errors]: any, index: number) => (
                <div className='fw-bolder fs-5 mt-2' key={index}>
                  <span>{key}:</span>
                  <ul>
                    {errors.map((error: string, index: number) => (
                      <li key={index} className='ms-4'>
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </Container>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export {ValidationErrorModal}
