import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../helpers'

type Props = {
  className?: string
  path: string
  svgClassName?: string
  small?: boolean
  style?: any
}

const KTSVG: React.FC<Props> = ({
  className = '',
  path,
  svgClassName = 'mh-50px',
  small = true,
  style = null,
}) => {
  return (
    <span className={`${small ? 'svg-icon' : ''} ${className}`}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} style={style} />
    </span>
  )
}

export {KTSVG}
