import {useEffect, useState} from 'react'

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  // isMobileTablet < 1400
  // isWindows >= 1400
  const {width} = windowDimensions
  const isMobileTablet = width < 1400
  const isWindows = width >= 1400

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {...windowDimensions, isMobileTablet, isWindows}
}
