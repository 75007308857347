import axios, {CancelTokenSource} from 'axios'
import {useCallback, useEffect, useRef} from 'react'

const useCancelToken = () => {
  const axiosSource = useRef<CancelTokenSource>(axios.CancelToken.source())
  const newCancelToken = useCallback(() => {
    return axiosSource.current.token
  }, [])

  useEffect(
    () => () => {
      axiosSource.current.cancel()
    },
    []
  )

  return {newCancelToken, isCancel: axios.isCancel}
}

export default useCancelToken
