const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

// Round half away from zero
function round(num, decimalPlaces = 2) {
  if (num < 0) {
    return -round(-num, decimalPlaces)
  }
  let p = Math.pow(10, decimalPlaces)
  let n = (num * p).toPrecision(15)
  return Math.round(Number(n)) / p
}

export {formatBytes, round}
