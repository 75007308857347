/* eslint-disable no-undef */
import axios from 'axios'
import {getAuth} from '../modules/auth'

const API_VERSION = process.env.REACT_APP_API_VERSION
const API_URL = process.env.REACT_APP_API_URL
const BASE_URL = `${API_URL}/${API_VERSION}`

const axiosConfig = axios.create({
  baseURL: BASE_URL,
  timeout: 2 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosConfig.interceptors.request.use(
  function (config) {
    const auth = getAuth()
    if (auth?.access_token) {
      config.headers.Authorization = `Bearer ${auth?.access_token}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axiosConfig.interceptors.response.use(
  (response) => {
    return response.data
  },
  async function (error) {
    return Promise.reject(error)
  }
)

export default axiosConfig
