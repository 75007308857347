import clsx from 'clsx'
import {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {SwapperComponent} from '../../../assets/ts/components'
import {toAbsoluteUrl} from '../../../helpers'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {useAsideProvider} from '../../../providers'
import {useLayout} from '../../core'
import {AsideFooterLayout, AsideMenuLayout, AsideSubLayout} from '../aside'

const AsideLayout = () => {
  const {classes} = useLayout()
  const {isMobileTablet, isWindows} = useWindowDimensions()
  const {activeFooter, setActiveFooter, hasShowSubmenu, setHasShowSubmenu} = useAsideProvider()
  const {currentUser} = useAuth()

  // BEGIN: Event main aside
  const handleMouseEnter = () => {
    if (currentUser) {
      setHasShowSubmenu(true)
    }
  }

  const handleMouseLeave = () => {
    setHasShowSubmenu(false)
  }

  const handleResetMenu = () => {
    const nodeList = document.querySelectorAll(
      '#kt_accordion_menu_main_aside--main .aside--main__center__item'
    )
    const filteredList: any = []
    nodeList.forEach((item) => {
      if (!item?.classList?.contains('child') && !item?.classList?.contains('active')) {
        filteredList.push(item)
      } else if (item?.classList?.contains('active')) {
        const itemChild: any = item?.childNodes?.[0]?.childNodes?.[0]
        if (itemChild && itemChild?.classList?.contains('collapsed')) {
          itemChild.click()
        }
      }
    })
    filteredList.forEach((item) => {
      const element = item?.childNodes?.[0]?.childNodes?.[0]
      if (!element?.classList?.contains('collapsed')) {
        element.click()
      }
    })
  }

  // reset on window
  useEffect(() => {
    if (!hasShowSubmenu && isWindows) {
      handleResetMenu()
    }
  }, [hasShowSubmenu, isWindows])

  // reset on mobile
  useEffect(() => {
    const handleClassChange = () => {
      const element = document.getElementById('gori_aside')
      if (!element?.classList?.contains('drawer-on')) {
        handleResetMenu()
      }
    }

    const observer = new MutationObserver(handleClassChange)
    const config = {attributes: true, attributeFilter: ['class']}
    const element = document.getElementById('gori_aside')
    if (element && isMobileTablet) {
      observer.observe(element, config)
    }

    return () => {
      if (element) {
        observer.disconnect()
      }
    }
  }, [isMobileTablet])
  // BEGIN: Event main aside

  // BEGIN: Event footer aside
  useEffect(() => {
    const handleClassChange = () => {
      if (activeFooter) {
        const element: any = document.getElementById(
          `aside-footer-${activeFooter === '/settings' ? 'settings' : 'accounts'}`
        )
        if (!element?.classList?.contains('show')) {
          setActiveFooter((prev) => {
            return activeFooter !== prev ? prev : null
          })
        }
      }
    }

    const observer = new MutationObserver(handleClassChange)
    const config = {attributes: true, attributeFilter: ['class']}
    const element = document.getElementById(
      `aside-footer-${activeFooter === '/settings' ? 'settings' : 'accounts'}`
    )
    if (element) {
      observer.observe(element, config)
    }

    return () => {
      if (element) {
        observer.disconnect()
      }
    }
  }, [activeFooter, isWindows, setActiveFooter])

  const handleToggleClick = () => {
    setActiveFooter(null)
    handleResetMenu()
  }

  useEffect(() => {
    const toggleElement = document.getElementById('gori_aside_toggle')
    if (toggleElement) {
      toggleElement.addEventListener('click', handleToggleClick)
    }

    return () => {
      if (toggleElement) {
        toggleElement.removeEventListener('click', handleToggleClick)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // END: Event footer aside

  return (
    <div className='aside'>
      <div
        id='gori_aside'
        className={clsx(
          'aside--main py-3 py-xxl-9 d-flex flex-column justify-content-between',
          classes.aside.join(' ')
        )}
        data-kt-drawer='true'
        data-kt-drawer-name='aside'
        data-kt-drawer-activate='{default: true, xxl: false}'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default: '170px', 'sm': '220px'}"
        data-kt-drawer-direction='start'
        data-kt-drawer-toggle='#gori_aside_toggle'
      >
        <div className='mb-6 mb-xxl-20'>
          <div className='d-flex justify-content-center mb-6 mb-xxl-9' id='gori_aside_logo'>
            <Link to='/a1-batches' onClick={() => SwapperComponent.removeInstances()}>
              <img
                alt='Logo'
                src={toAbsoluteUrl(
                  `/media/logos/${
                    (isMobileTablet && 'big-shipbae-logo-white.svg') ||
                    (isWindows && 'vertical-shipbae-logo.svg')
                  }`
                )}
                className={(isMobileTablet && 'h-30px logo mt-12 mb-10') || 'h-40px logo my-5'}
              />
            </Link>
          </div>
          <div
            id='gori_aside_menu'
            className='aside--main__center scroll-y'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <AsideMenuLayout className='aside--main' />
          </div>
        </div>
        <div id='gori_aside_footer' className='aside--main__footer'>
          <AsideFooterLayout />
        </div>
      </div>
      <div
        className={clsx('aside--sub', {show: hasShowSubmenu})}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <AsideSubLayout />
      </div>
    </div>
  )
}

export {AsideLayout}
