import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {SwapperComponent} from '../../../../../_gori/assets/ts/components'
import {toAbsoluteUrl} from '../../../../../_gori/helpers'

export function Header() {
  const intl = useIntl()
  // const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

  // const toggleIntegrations = () => {
  //   const dd = document.getElementById('integrations-dropdown')

  //   if (dd?.classList.contains('d-none')) {
  //     dd?.classList.remove('d-none')
  //     dd?.classList.add('d-flex')
  //   } else if (!dd?.classList.contains('d-none')) {
  //     dd?.classList.remove('d-flex')
  //     dd?.classList.add('d-none')
  //   }
  // }

  // const toggleMobileIntegrations = () => {
  //   const dd = document.getElementById('integrations-dropdown-mobile')

  //   if (dd?.classList.contains('d-none')) {
  //     dd?.classList.remove('d-none')
  //     dd?.classList.add('d-flex')
  //   } else if (!dd?.classList.contains('d-none')) {
  //     dd?.classList.remove('d-flex')
  //     dd?.classList.add('d-none')
  //   }
  // }

  const closeDTDropdown = () => {
    const dd = document.getElementById('integrations-dropdown')

    if (dd?.classList.contains('d-flex')) {
      dd?.classList.remove('d-flex')
      dd?.classList.add('d-none')
    }
  }

  const closeMobileDropdown = () => {
    const mobileDd = document.getElementById('integrations-dropdown-mobile')

    if (mobileDd?.classList.contains('d-flex')) {
      mobileDd?.classList.remove('d-flex')
      mobileDd?.classList.add('d-none')
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdown = document.getElementById('integrations-dropdown')
      const mobileDropdown = document.getElementById('integrations-dropdown-mobile')

      if (
        dropdown &&
        !dropdown.contains(event.target) &&
        !event.target.closest('.integrations-container')
      ) {
        closeDTDropdown()
      }

      if (
        mobileDropdown &&
        !mobileDropdown.contains(event.target) &&
        !event.target.closest('.integrations-container-mobile')
      ) {
        closeMobileDropdown()
      }
    }

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        closeDTDropdown()
        closeMobileDropdown()
      }
    }

    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('keydown', handleEscKey)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [])

  return (
    <div className='fixed-top bg-white border-bottom py-5'>
      <header className='container d-flex flex-wrap justify-content-between justify-content-md-center'>
        <div className='d-flex align-items-center me-md-auto text-dark text-decoration-none '>
          <Link to={'/'}>
            <img
              alt='logo'
              src={toAbsoluteUrl('/media/logos/big-shipbae-logo.svg')}
              className='h-30px'
            />
          </Link>
          {/* <span className='text-black-50 fs-3 px-2'>|</span>
          <Link to='/help-center' className='text-black-50 text-hover-primary fs-3'>
            {intl.formatMessage({id: 'HELP_CENTER'})}
          </Link> */}
        </div>

        {/* <div className='integrations-container position-relative d-none d-md-block'>
          <button
            className='btn'
            style={{fontSize: '16px', color: '#94A3B8'}}
            onClick={toggleIntegrations}
          >
            {intl.formatMessage({id: 'INTEGRATIONS'})}
            <img
              className='ms-2 d-inline-block'
              src='/media/gori/arrows/arrow_down.svg'
              alt='down-chevron'
            />
          </button>
          <ul
            id='integrations-dropdown'
            className='d-none nav list-unstyled flex-column position-absolute w-100 bg-white border rounded shadow-sm'
          >
            <li>
              <Link
                to='/integrations/amazon'
                className='d-block px-6 py-2'
                onClick={toggleIntegrations}
              >
                Amazon
              </Link>
            </li>
            <li>
              <Link
                to='/integrations/shipstation'
                className='d-block px-6 py-2'
                onClick={toggleIntegrations}
              >
                ShipStation
              </Link>
            </li>
            <li>
              <Link
                to='/integrations/shopify'
                className='d-block px-6 py-2'
                onClick={toggleIntegrations}
              >
                Shopify
              </Link>
            </li>
            <li>
              <Link
                to='/integrations/walmart'
                className='d-block px-6 py-2'
                onClick={toggleIntegrations}
              >
                Walmart
              </Link>
            </li>
            <li>
              <Link
                to='/integrations/ebay'
                className='d-block px-6 py-2'
                onClick={toggleIntegrations}
              >
                EBay
              </Link>
            </li>
            <li>
              <Link
                to='/integrations/tiktok'
                className='d-block px-6 py-2'
                onClick={toggleIntegrations}
              >
                TikTok
              </Link>
            </li>
          </ul>
        </div> */}

        <ul className='nav nav-pills'>
          <li className='nav-item'>
            <Link
              to='/auth'
              onClick={() => SwapperComponent.removeInstances()}
              className='btn btn-outline text-primary btn-active-light-primary rounded-pill px-5 px-md-8'
            >
              {intl.formatMessage({id: 'LOG_IN'})}
            </Link>
          </li>
          {/* <li className='nav-item'>
            <a
              className='btn btn-outline btn-primary hover-orange rounded-pill px-5 px-md-8'
              href={document.location.protocol + '//' + BASE_DOMAIN + '/auth/registration'}
            >
              {intl.formatMessage({id: 'START_FREE'})}
            </a>
          </li> */}
        </ul>
      </header>

      {/* <div className='integrations-container-mobile position-relative d-block d-md-none'>
        <button
          className='btn pb-0'
          style={{fontSize: '16px', color: '#94A3B8'}}
          onClick={toggleMobileIntegrations}
        >
          {intl.formatMessage({id: 'INTEGRATIONS'})}
          <img
            className='ms-2 d-inline-block'
            src='/media/gori/arrows/arrow_down.svg'
            alt='down-chevron'
          />
        </button>
        <ul
          id='integrations-dropdown-mobile'
          className='d-none nav list-unstyled flex-column position-absolute w-100 bg-white'
        >
          <li>
            <Link
              to='/integrations/amazon'
              className='d-block px-6 py-2'
              onClick={toggleMobileIntegrations}
            >
              Amazon
            </Link>
          </li>
          <li>
            <Link
              to='/integrations/shipstation'
              className='d-block px-6 py-2'
              onClick={toggleMobileIntegrations}
            >
              ShipStation
            </Link>
          </li>
          <li>
            <Link
              to='/integrations/shopify'
              className='d-block px-6 py-2'
              onClick={toggleMobileIntegrations}
            >
              Shopify
            </Link>
          </li>
          <li>
            <Link
              to='/integrations/walmart'
              className='d-block px-6 py-2'
              onClick={toggleMobileIntegrations}
            >
              Walmart
            </Link>
          </li>
          <li>
            <Link
              to='/integrations/ebay'
              className='d-block px-6 py-2'
              onClick={toggleMobileIntegrations}
            >
              EBay
            </Link>
          </li>
          <li>
            <Link
              to='/integrations/tiktok'
              className='d-block px-6 py-2'
              onClick={toggleMobileIntegrations}
            >
              TikTok
            </Link>
          </li>
        </ul>
      </div> */}
    </div>
  )
}
