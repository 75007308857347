import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {Link, Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {Login} from './components/Login'

const AuthLayout = () => {
  const intl = useIntl()
  const location = useLocation()
  const isLoginPage = useMemo(
    () => location.pathname === '/auth/login' || location.pathname === '/auth',
    [location.pathname]
  )

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundColor: '#E4EFEE',
        backgroundImage: `url(${toAbsoluteUrl('/media/gori/login/gori_bg_rocket_1.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <Link to={isLoginPage ? '/auth' : 'login'} className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/big-shipbae-logo.svg')}
            className='h-60px'
          />
          <h1 className='text-center mt-3 fw-boldest text-gray-800'>
            {intl.formatMessage({id: 'PARTNER_PORTAL'})}
          </h1>
        </Link>
        <Outlet />
      </div>
    </div>
  )
}

const AuthPage = () => {
  return (
    <>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='login' element={<Login />} />
          <Route path='*' element={<Navigate to='login' />} />
        </Route>
      </Routes>
    </>
  )
}

export {AuthPage}
